/* App.css */

@font-face {
  font-family: 'HandTitle';
  src: url('./fonts/waltographUI.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'TextFont';
  src: url('./fonts/Ubuntu-Title.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'StoryFont';
  src: url('./fonts/Chocolate Covered Raindrops BOLD.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'FancyFont';
  src: url('./fonts/Alamak.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: 'TextFont', sans-serif;
  background-color: #f0f2f5; /* Light background for better contrast */
  margin: 0;
  padding: 0;
}

.Subtitle {
  font-family: 'HandTitle', sans-serif;
  font-size: 40px;
  color: #333333; /* Darker color for better readability */
}

.Title {
  font-family: 'HandTitle', sans-serif;
  font-size: 60px;
}

.Button {
  font-family: 'TextFont', sans-serif;
  color: #ffffff;
  backdrop-filter: blur(4px);
  border-radius: 15px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.3);
  font-size: 27px !important;
  width: 420px;
  height: 60px;
}

.App-logo {
  height: 30vmin;
  pointer-events: none;
  border-radius: 50%;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s;
}

.App-logo:hover {
  transform: scale(1.05);
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 15s linear;
  }
}

.App-link {
  color: #ff8c00;
  text-decoration: none;
  font-weight: bold;
  border-bottom: 2px solid transparent;
  transition: color 0.3s, border-bottom 0.3s;
}

.App-link:hover {
  color: #ffa726;
  border-bottom: 2px solid #ffa726;
}

.Background {
  background-size: cover;
  background-position: center;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  text-align: center;
  position: absolute;
}

.text {
  font-family: 'TextFont', sans-serif !important;
  font-size: 20px !important;
}

button {
  font-family: 'TextFont', sans-serif !important;
  font-size: 20px !important;
  padding: 10px 20px;
  border: none;
  border-radius: 25px;
  background-color: rgba(98, 26, 232, 0.6);
  border-radius: 15px;
  color: white;
  cursor: pointer;
  transition: background 0.3s;
}

button:hover {
  background: #b8c7e6;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.NavBar {
  background-color: rgba(98, 26, 232, 0.6) !important;
  color: #ffffff;
  backdrop-filter: blur(4px) !important;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.3);
  display: flex;
  padding: 10px 20px; /* Added padding for better spacing */
  justify-content: space-between;
}

/* Ensure the background image is proportional */
.Background {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  text-align: center;
  position: absolute;
}
